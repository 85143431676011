<template>
  <div class="visualizationItems">
    <div class="visualizationItems-goBack">
      <router-link :to="{ name: 'visualization' }">
        <icon name="chevron-left" scale="1.8" class="mr-50"></icon>
        <span>Вернуться к категориям</span>
      </router-link>
    </div>
    <div class="visualizationItemBlock orthopedicsBg">
      <img src="../../assets/images/visualizations/orthopedics_logo.png" alt />
      <div class="visualizationItemContent">
        <div class="visualizationItemSearchBar">
          <span class="visualizationItemSearchTitle">Ортопедия, протезирование</span>
          <div class="visualizationItemInput">
            <input type="text" class="searchBar" v-model="key_word" v-on:keyup="applySearch()" placeholder="Поиск" />
            <icon name="search" class="iconSearch"></icon>
          </div>
          <span class="visualizationItemInput-info"
            >Найдите интересующую Вас визуализацию используя окно для поиска</span
          >
        </div>
        <div class="visualizationItemContentOtherCategories">
          <router-link :to="{ name: 'visualization_surgery' }">
            <img src="../../assets/images/visualizations/surgery_and_implantation_logo.png" alt />
            <span class="visualizationItemContentOtherCategoriesTitle">Хирургия</span>
          </router-link>
          <router-link :to="{ name: 'visualization_orthodontics' }">
            <img src="../../assets/images/visualizations/orthodontics_logo.png" alt />
            <span class="visualizationItemContentOtherCategoriesTitle">Ортодонтия</span>
          </router-link>
          <router-link :to="{ name: 'visualization_terapy' }">
            <img src="../../assets/images/visualizations/terapy_logo.png" alt />
            <span class="visualizationItemContentOtherCategoriesTitle">Терапия, анатомия</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="visualizationItemList">
      <visualizationItem
        v-for="item in visualizationsList"
        :key="item._id"
        :favorites="favorites"
        :isTD="item.files3DList.length ? true : false"
        :isVR="item.filesVR ? true : false"
        :type="item.category"
        :title="item.title"
        :preview="item.preview"
        :id="item._id"
      ></visualizationItem>
    </div>
    <infinite-loading @infinite="getVisualizationItems" ref="infiniteLoading">
      <span slot="no-more">Всё загружено!</span>
    </infinite-loading>
  </div>
</template>
<script>
import visualizationItem from "./visualization_item.vue";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "visualization_orthopedics",
  components: { visualizationItem, InfiniteLoading },
  data() {
    return {
      // page: 1,
      endpoint: "visualizations/orthopedics",
      lazyTimer: false,
      key_word: "",
      // visualizationsList: [],
      // favorites: []
    };
  },
  computed: {
    visualizationsList() {
      return this.$store.getters.getOrthopedicsItems;
    },
    favorites() {
      return this.$store.getters.getOrthopedicsFavoriteItems;
    },
    page() {
      return this.$store.getters.getOrthopedicsPage;
    },
  },
  beforeRouteLeave(to, from, next) {
    localStorage.setItem("prevScroll", window.pageYOffset);
    localStorage.setItem("prevScrollPage", from.name);
    next();
  },
  methods: {
    getVisualizationItems($state) {
      let option = {
        params: {
          page: this.page,
          key_word: this.key_word,
        },
      };
      this.$http.get(this.endpoint, option).then((response) => {
        // console.log(response.data);
        if (response.data.visualizationsList) {
          // this.visualizationsList = this.visualizationsList.concat(
          //   response.data.visualizationsList
          // );
          // this.favorites = response.data.favorites;
          // this.page++;

          const page = this.page + 1;
          this.$store.dispatch("setOrthopedicsItems", {
            page,
            list: response.data.visualizationsList,
            favorites: response.data.favorites,
          });

          $state.loaded();
        } else {
          console.log("We can't get items for this category!");
          $state.complete();
        }
      });
    },
    applySearch() {
      if (this.lazyTimer) {
        clearTimeout(this.lazyTimer);
      }
      this.lazyTimer = setTimeout(() => {
        // this.page = 1;
        // this.visualizationsList = [];
        this.$store.dispatch("resetOrthopedicsItems");
        console.log("refresh");
        this.$nextTick(() => {
          this.$refs.infiniteLoading.$emit("$InfiniteLoading:reset");
        });
      }, 500);
    },
  },
};
</script>
<style></style>

<template>
  <div class="visualizationItem">
    <div class="visualizationItemContent">
      <img :src="preview" alt="" class="visualizationItemContent-preview" />
      <div class="visualizationItemContent-description">
        <button v-if="isLoggedIn" v-on:click="updateFavorites" type="button" class="visualizationItemContent-favorites">
          <icon v-if="isFavorite" name="star" scale="2.3"></icon>
          <span v-if="!isFavorite" class="favorites-popup">Добавть в избранное</span>

          <icon v-if="!isFavorite" name="regular/star" scale="2.3"></icon>
          <span v-if="isFavorite" class="favorites-popup">Убрать из избранного</span>
        </button>
        <span class="visualizationItemContent-description-text">{{ title }}</span>
        <div class="visualizationItemContent-description-links">
          <router-link :to="{ name: 'viewerVRremote', params: { set: id } }" v-if="isVR">
            <img src="./../../assets/images/VRicon.png" alt="" />
          </router-link>
          <router-link :to="{ name: 'viewer3Dremote', params: { type: 'visualization', set: id } }" v-if="isTD">
            <img src="./../../assets/images/3Dicon.png" alt="" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Visualization_item",
  data() {
    return { endpoint: "profile/favorites", favoritesCurrent: [] };
  },
  props: ["type", "id", "title", "preview", "isVR", "isTD", "favorites"],
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    isFavorite() {
      return this.favoritesCurrent.indexOf(this.id) != -1 ? true : false;
    },
  },
  methods: {
    updateFavorites() {
      let data = {
        visualizationId: this.id,
        type: !this.isFavorite ? "add" : "remove",
      };
      this.$http.post(this.endpoint, data).then((response) => {
        if (response.data.success) {
          var idx = this.favoritesCurrent.indexOf(this.id);
          if (idx != -1) {
            this.favoritesCurrent.splice(idx, 1);
          } else {
            this.favoritesCurrent.push(this.id);
          }
        }
      });
    },
  },
  created() {
    this.favoritesCurrent = this.favorites;
  },
};
</script>
<style lang="sass">
.visualizationItem
	position: relative
	display: flex
	min-height: 150px
	.visualizationItemContent
		.visualizationItemContent-preview
			display: block
			width: 100%
			height: 100%
			object-fit: cover
		.visualizationItemContent-description
			display: flex
			flex-direction: column
			justify-content: space-between
			position: absolute
			top: 0
			left: 0
			right: 0
			bottom: 0
			text-shadow: 0px 0px 2px #fff
			font-weight: bold
			transition: all 0.3s
			background: linear-gradient(to bottom, rgba(255,255,255,0.7) 0%,rgba(255,255,255,0) 100%)
			&:hover
				background: linear-gradient(to bottom, rgba(255,255,255,0.9) 0%,rgba(255,255,255,0.1) 100%)
				.visualizationItemContent-favorites
					opacity: 1
				.visualizationItemContent-description-links
					opacity: 1
			@media ( pointer: coarse )
				background: linear-gradient(to bottom, rgba(255,255,255,0.9) 0%,rgba(255,255,255,0.1) 100%)
				.visualizationItemContent-favorites
					opacity: 1 !important
				.visualizationItemContent-description-links
					opacity: 1 !important
			.visualizationItemContent-favorites
				display: block
				position: absolute
				top: 0
				right: 0
				width: 60px
				height: 60px
				color: #ffe13b
				border: 1px solid transparent
				background: transparent
				opacity: 0
				transition: all 0.3s
				z-index: 999
				cursor: pointer
				outline-color: transparent
				& .favorites-popup
					display: block
					position: absolute
					bottom: 85%
					left: 50%
					font-size: 12px
					color: #333
					background: #eee
					border: 1px solid transparent
					border-radius: 4px
					padding: 4px
					opacity: 0
					transition: all 0.3s
				&:hover
					color: #f7bc07
					.favorites-popup
						opacity: 1
			.visualizationItemContent-description-text
				font-size: 1.1rem
				padding: 1rem
			.visualizationItemContent-description-links
				display: flex
				opacity: 0
				justify-content: space-around
				align-items: center
				padding-bottom: 0.5rem
				transition: all 0.3s
				a
					display: block
					height: inherit
					width: 35%
					&:hover
						background: radial-gradient(ellipse at center, rgb(51, 177, 219) 0%, transparent 70%, transparent 100%)
					img
						display: block
						width: 100%
						object-fit: contain
						height: 100%
</style>
